import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import Carousel from 'react-bootstrap/Carousel';
import { LoadItems } from './loaditems.js';
import ReactGA from "react-ga4";

const lngs = [
  { code: "en", native: "English" },
  { code: "ja", native: "日本語" },
];
const TRACKING_ID = "G-NQ9FP6CR58"; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

export default function Home(props) {
    const { location } = props;
    const { t, i18n } = useTranslation();
    const handleTrans = (code) => {
        i18n.changeLanguage(code);
      };
    const welCome = t('home_welcome');
    const welCome2 = t('home_welcome2');
    const valueParam = window.location.href.split('/')[3]
    console.log(valueParam);
    
    ReactGA.send({ hitType: "pageview", page: "/", title: "Home" });
     
    let dorio, myText;
    if (valueParam === "%E2%99%A5") {
        dorio = "Heartcore";
        myText = "Dunes is my personal site to display some of the latest projects I have directed or been involved recently.";   
    } else if (valueParam === "peach") {
        dorio = "Google";
        myText = "Dunes is my personal site to display some of the latest projects I have directed or been involved recently.";       
    } else {
        dorio = "";
        myText = "Dunes is my personal site to display some of the latest projects I have directed or been involved recently.";
    }
    console.log(dorio);

    // Define the video URL
    const videoUrl = "https://res.cloudinary.com/djbbh4y3v/video/upload/v1717397353/homes2_jnw2ns.mp4";

    return ( 
        <div className="container-fluid homeContent">
            {/* Carousel Wrapper */}
            <Carousel fade controls={false} className="home-carousel">
                {/* Single Item */}
                <Carousel.Item>
                    <LoadItems 
                        Index={0}
                        Home={'yes'}
                        welCome={welCome}
                        welCome2={welCome2}
                        Type={'video'}
                        Dorio={dorio}
                        Url={videoUrl}  // Pass the video URL as a prop
                        Title={'Welcome'}
                        Text={myText}
                        Text2={''}
                        Tags={['', '', '']}
                        Sound={false}
                        Link={''}
                        Signature={true}
                    />
                </Carousel.Item>
            </Carousel>
        </div>
    )
}
