import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from "./LanguageSwitcher";
import logo from '../logo.svg';
import dunes from '../dunes.svg';
import github from '../icon-github.svg';
import soundcloud from '../icon-soundcloud.svg';
import linkedin from '../icon-linkedin.svg';
import '../App.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Offcanvas from 'react-bootstrap/Offcanvas';

const lngs = [
  { code: "en", native: "English" },
  { code: "ja", native: "日本語" },
];
const myLoc = window.location.href.split('/')[3];

const Navigation = props => {
    const { t, i18n } = useTranslation();
    const [menuOpen, setMenuOpen] = useState(false);

    const handleTrans = (code) => {
        i18n.changeLanguage(code);
    };

   const toggleMenu = () => {
    	setMenuOpen(!menuOpen);
	};

    return (
        <Navbar expand="" className="navbar navbar-dark position-absolute">
            <Container>
                {/* NavBar Brand */}
                <Navbar.Brand href="/"><img src={dunes} width="60" height="auto" alt="Dunes" />
                &nbsp;&nbsp;<strong className={`${menuOpen ? 'show-txt' : ''}`}>DUNES</strong>
                </Navbar.Brand>

                {/* Custom Burger Menu */}
                <div className={`burger-menu ${menuOpen ? "open ms-auto" : "ms-auto"}`} onClick={toggleMenu}>
                    <div className="line1"></div>
                    <div className="line2"></div>
                    <div className="line3"></div>
                </div>

                {/* Offcanvas */}
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand`}
                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                    placement="top"
                    data-bs-scroll="false"
                    data-bs-backdrop="false"
                    className={menuOpen ? "show" : ""}
                >

					
                    <Offcanvas.Body className="">
                        <Nav className="myMenumd">
	                        	<div className="container">
		                        	<div class="row">
			                            <Nav.Link className={myLoc === 'projects' ? 'active col-12 col-md-4 text-start text-md-center' : 'col-12 col-md-4 text-start text-md-center'} href="projects">{t('menu_projects')}</Nav.Link>
			                            <Nav.Link className={myLoc === 'skills' ? 'active col-12 col-md-4 text-start text-md-center' : 'col-12 col-md-4 text-start text-md-center'} href="skills">{t('menu_skills')}</Nav.Link>
			                            <Nav.Link className={myLoc === 'contact' ? 'active col-12 col-md-4 text-start text-md-center' : 'col-12 col-md-4 text-start text-md-center'} href="contact">{t('menu_contact')}</Nav.Link>
			                            <div class="col-12 mt-5 text-start text-lg-center">
			                            <Nav.Link className="me-5" href="https://www.linkedin.com/in/julien-b-6179a818/" target="_blank"><img src={linkedin} width="30" height="auto" alt="LinkedIn" /></Nav.Link>
			                            <Nav.Link className="me-5" href="https://github.com/airbuzz" target="_blank"><img src={github} width="30" height="auto" alt="GitHub" /></Nav.Link>
			                            <Nav.Link className="me-0" href="https://soundcloud.com/airbuzz/tracks" target="_blank"><img src={soundcloud} width="30" height="auto" alt="Souncloud" /></Nav.Link>
			                            <div className="col-12 mt-5">
			                            	<LanguageSwitcher />
			                            </div>
		                            </div>
	                            </div>
                            </div>
                        </Nav>
                    </Offcanvas.Body>
                </Navbar.Offcanvas>

            </Container>
        </Navbar>
    );
}

export default Navigation;
