import React from 'react'
import { Helmet } from "react-helmet";

export default function Skills() {
    return (
    <div>
        <Helmet>
            <title>Expertise Across a Wide Range of Technologies | Dunes Skills & Servcies</title>
            <meta name="description" content="Dunes is a team of highly skilled professionals with expertise across a wide range of technologies. From software development to cloud computing and cybersecurity, we have the skills and experience to provide innovative solutions to businesses worldwide. Explore our skills page to learn more about our expertise and how we can help your organization stay ahead in the fast-paced world of technology." />
        </Helmet>
           <div className="masthead-about">
            <div className="container h-100">
                <div className="row">
                    <div className="col"><h2 className="fw-strong text-white headline-p pb-4">Skills</h2></div>
                </div>
                <div className="row align-items-center">
                  <div className="col-12 col-md-6 text-xs-center text-md-left">
                        <h4 className="fw-strong text-white headline-p pb-2">Hard Skills</h4>
                        <div className = "chart mx-auto mt-6">
                            <div className="chartItem mr-auto">
                                <label>Web Development</label>
                                <div className="chartBar chart1"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>Technical Specs And Requirements</label>
                                <div className="chartBar chart2"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>Product Development</label>
                                <div className="chartBar chart3"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>UI/UX</label>
                                <div className="chartBar chart4"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>Customer Journey Mapping</label>
                                <div className="chartBar chart5"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>Digital Marketing / PR</label>
                                <div className="chartBar chart6"></div>                
                            </div>
                        </div>
                    </div>
                  <div className="col-12 col-md-6 text-xs-center text-md-left">
                        <h4 className="fw-strong text-white headline-p pb-2">Software</h4>
                        <div className = "chart mx-auto mt-6 ">
        
                            <div className="chartItem mr-auto">
                                <label>Figma</label>
                                <div className="chartBar chart7"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>Adobe Suite</label>
                                <div className="chartBar chart8"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>Microsoft Suite</label>
                                <div className="chartBar chart9"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>Keynote</label>
                                <div className="chartBar chart10"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>Jira / Notion / Trello</label>
                                <div className="chartBar chart11"></div>                
                            </div>
                            <div className="chartItem mr-auto">
                                <label>UI/UX</label>
                                <div className="chartBar chart12"></div>                
                            </div>
                        </div>
                        </div>
                  </div>
                </div>
              </div>
        </div>
    )

}