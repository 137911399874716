import React, { useState, useRef } from "react";
import { Helmet } from "react-helmet";
import { LoadItems } from './loaditems.js';
import { Spinner } from './spinner.js';
import Loader from './loader'
import Carousel from 'react-bootstrap/Carousel';
import truckPilot from '../images/truckpilot.jpg';
import imageEvielab from "../images/evielab.jpg";
import imageCollosse from "../images/collosse.jpg";
//import videoCL from "../videos/cl.mp4";
//import videoBurger from "../videos/bstudio7.mp4";
//import videoIntro from "../videos/battle.mp4";
//import videoRespond from "../videos/respond.mp4";
import runHeatMap from '../images/run_heatmap2.jpg';

export default function Projects() {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const videoRef = useRef(null);
    const [mutedVideo, setMutedVideo] = useState(true);

    const unmuteVideo = () => {
        videoRef.current.muted = false;
    };
   
    
    const videoRespond ="https://res.cloudinary.com/djbbh4y3v/video/upload/v1717629068/respond_qjrcgo.mp4";
    const videoIntro ="https://res.cloudinary.com/djbbh4y3v/video/upload/v1717628925/battle_ticozs.mp4";
    const videoBurger ="https://res.cloudinary.com/djbbh4y3v/video/upload/v1717628925/bstudio7_ilm4qn.mp4";
    const videoCL ="https://res.cloudinary.com/djbbh4y3v/video/upload/v1717628987/cl_is12f4.mp4";
	 const videoOdigo = "https://res.cloudinary.com/djbbh4y3v/video/upload/v1717628416/odigo_b4dstf.mp4";
    // Array of objects containing all parameters
    const videoData = [
        {
            Type: 'image',
            Title: 'The Collossium',
            Text: 'The Collossium is my personal blog where I talk about various topics ranging from AI, transhumanism and societals issues. ',
            Text2: 'While the design is somewhat ironic; it plays with the oposites of genre and thoughts.',
            Tags: ['Design', 'wordpress', 'Blog'],
            Sound: false,
            Link: 'https://www.thecollossium.com',
            BtnLabel:'Visit Site'
        },
        {
            Type: 'image',
            Title: 'TruckPilot',
            Text: 'TruckPilot is a draft prototype made for a client in the span of 2 days.',
            Text2: 'It includes all Figma components necessary. Please click below to check the Figma file.',
            Tags: ['UI/UX', 'Figma', 'Prototype'],
            Sound: false,
            Link: 'https://www.figma.com/file/rbSF2eaL4ZEAYeTvYr29fY/TRUCK-PILOT?node-id=2451%3A261&t=pawnF7ZQnKewaLdu-1',
            BtnLabel:'View Figma'
        },
        {
            Type: 'image',
            Title: 'Evielab',
            Text: 'I joined Evielab in the early stages to help them setup their IT & manage B2B relationships in Los Angeles.',
            Text2: 'In charge of marketing, I was communicating directly with the head of their PR agency for the release of their products. I supervised various sides of product development and helped them reaching success.',
            Tags: ['Product Developnent', 'PR', 'Vendors Management'],
            Sound: false,
            Link: 'http://www.evielab.com',
            BtnLabel:'Visit Website'
            
        },
        {
            Type: 'video',
            Title: 'CL by C.ルメール',
            Text: 'CL is a fashion brand created by worldly recognized jockey Christophe Lemaire, I was asked to redesign their online shopping website using Shopify.',
            Text2: 'I was in charge from concept to delivery from UI/UX to Shopify custom developments.',
            Tags: ['Branding', 'UI/UX', 'Shopify'],
            Sound: false,
            Link: 'http://www.c-lemaire.co.jp',
            BtnLabel:'Visit Website'
        },
        {
            Type: 'video',
            Title: 'Burgerstudio',
            Text: 'Burgerstudio is a Tokyo based game studio originating from Kyoto. They are working for some of the most prestigious names of the gaming industry such as Nintendo, Bandai and Microsoft.',
            Text2: 'I was in charge from concept to delivery of UI/UX to Wordpress development and optimization.',
            Tags: ['Branding', 'UI/UX', 'Wordpress'],
            Sound: false,
            Link: 'http://www.burgerstudio.jp',
            BtnLabel:'Visit Website'
            
        },
        {
            Type: 'video',
            Title: 'Odigo Travel',
            Text: 'Founded the company in 2013, acted alternatively as CEO / Product  Manager while taking care of investors relations. The company grew to 26 people and the plateform was widely recognized for its unique design.',
            Text2: 'Odigo was a trip planner focusing on travel in Japan to help foreigners find their ways with spots taylored by locals.',
            Tags: ['Founder', 'Product Manager', 'Investors Relations'],
            Sound: false,
            Link: ''
        },
        {
            Type: 'video',
            Title: 'The Battle For Your Mind',
            Text: 'Directing & realising a documentary about social engineering in Japan. Project is still on-going & expected to be released  sometimes in 2024.',
            Text2: 'Leading a team of various people depending on the needs and also leading interviews with contributors & VIPs.',
            Tags: ['Movie Direction', 'Social Engineering', 'Interviews'],
            Sound: true,
            Link: ''
        },
        {
            Type: 'video',
            Title: 'Respond Media',
            Text: 'Co-managed the company focusing on web accessibility before it became Odigo Japan. The company was mainly building top notch responsive websites and web applications.',
            Text2: 'Collaborating with various clients to help them achieve their online goals.',
            Tags: ['Accessibility', 'Management', 'Web Development','UI / UX'],
            Sound: false,
            Link: ''
        },
        {
            Type: 'image',
            Title: 'Data Visualisation',
            Text: 'Mapping my run through tokyo from the last 10 years, I have been doing with my Meetup Group.',
            Text2: 'The group has been less active the past 4 years but still organizing runs sometimes.',
            Tags: ['Data Visualization', 'Leadership', 'Runnning'],
            Sound: false,
            Link: ''
        }
    ];

    // Array of video URLs
    const videoUrls = [imageCollosse,truckPilot,imageEvielab, videoCL, videoBurger, videoOdigo, videoIntro, videoRespond,runHeatMap];

    // Ensure both arrays have the same length
    if (videoData.length !== videoUrls.length) {
        console.error("Lengths of videoData and videoUrls arrays don't match.");
        return null;
    }

    return (
        <div>
            <Helmet>
            <title>Dunes |  Digital Experience, UI/UX in Tokyo, Japan</title>
                <meta name="description" content="Dunes, UI/UX' personal portfolio of Julien Bourdon Digital Experience and web development, IT consulting that have helped businesses worldwide achieve their digital transformation goals. Our team of skilled professionals is dedicated to delivering innovative IT solutions that drive business growth and success. Browse our projects today to see how we can help your organization stay ahead in the fast-paced world of technology." />
            </Helmet>
            <div className="homeContent">
                
                <Carousel fade controls={true} interval={null} activeIndex={index} onSelect={handleSelect}>
                    {videoUrls.map((videoUrl, idx) => (
                        <Carousel.Item key={idx}>
                            <Loader />
                            <LoadItems
                                Index={index}
                                Url={videoUrl}
                                {...videoData[idx]} // Spread the video data object to pass all parameters
                            />
                        </Carousel.Item>
                    ))}
                </Carousel>
            </div>
        </div>
    );
}

