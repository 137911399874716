import React from 'react'

export default function About() {
    
    return (
       <div className="masthead-about">
        <div className="container h-100">
            <div className="row h-100 align-items-center">
              <div className="col-12 text-center">
                <h1 className="fw-strong text-white headline-p">About</h1>
                <p className="lead text-white mt-4 ">My name  is Julien, I am originally from Brittany in France.
I have been working in IT building Apps and websites for about 20 years. However I wrote my first line of code on a Thomson MO-5 when I got his first computer at 6 years old. 
Product development has been his passion since I first started out. Seeing things come to fruition, pulling in all the various elements of a product, and bringing it all together is what gives me a sense of satisfaction.\
Recently I have been more involved in products development rather than exclusively online applications. 
Outside of my working life , I am passionate about music and running and I love maps.</p>
              </div>
            </div>
          </div>
    </div>
    )

}