import React, { useState, useRef, useEffect } from "react";
import ChatbotApp from './chat5';
import Typewriter from 'typewriter-effect';
import CountUp from 'react-countup';
import signatureImg from '../images/sign.png';

export function LoadItems({ ref=null, Index,welCome=null,welCome2=null,Home= null,Type,Dorio= null,Url,Title,Text,Text2 = null,Tags, Sound=null, Link = null, Signature = null,BtnLabel=null}) {
    const vdoStyle = {
        minWidth: "100%",
        minHeight: "100%"
    };
	 const customStyle = {
        marginRight: '1rem',
        marginBottom: '6rem',
        display: 'none',
        position: 'fixed',
        right: 0,
        bottom: 0,
        pointerEvents: 'none',
        overflow: 'hidden',
        height: '65vh',
        border: '2px solid #e2e8f0',
        borderRadius: '0.375rem',
        boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        width: '30rem'
    };
    
    const positionStyle = {
        position: "fixed",
        width: "100%",
        height: "100%"
    };

    const chatStyle ={
	    overflow:'hidden',
	    height: '80vh;',
	     width: '100%'
    };

    const videoRef = useRef(null);
    const [mutedVideo, setMutedVideo] = useState(true);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.muted = mutedVideo;
        }
    }, [mutedVideo]);

    return (
        <div>
            {Type === 'image' ? (
                <img
                    className="d-block h-100 img-carousel zoom-image"
                    src={Url}
                    alt={Title}
                />
            ) : (
                <video
                    style={vdoStyle}
                    ref={videoRef}
                    playsInline
                    autoPlay
                    muted={mutedVideo}
                    loop
                >
                    <source
                        className="h-100"
                        src={Url}
                        type="video/mp4"
                    />
                </video>
            )}
            <div className="background-suttle"></div>
            <div className={Index === 6 ? 'muteBtn d-block' : 'd-none muteBtn'}>
                <button onClick={() => setMutedVideo(!mutedVideo)} className={mutedVideo ? "active muted" : "inactive unmuted"}>
                    {mutedVideo ? "" : ""}
                </button>
            </div>
            <div className="video-caption">
                <div className="content">
                    <h3 className="highLight">
                        {Dorio !== null ? (
                            <Typewriter
                                onInit={(typewriter) => {
                                    typewriter.typeString(welCome +'<span> ' + Dorio + '</span> ' + welCome2)
                                        .callFunction(() => {
                                            console.log('String typed out!');
                                        })
                                        .pauseFor(2500)
                                        .callFunction(() => {
                                            console.log('All strings were deleted');
                                        })
                                        .start();
                                }}
                            />
                        ) : (
                            <span>{Title}</span>
                        )}
                    </h3>
                    {Index === 8 ? (
                        <div className="container mt-3">
                            <div className="row animatedNumber text align:center">
                                <div className="col">
                                    <span><CountUp duration={1.5} start={1} end={908} /></span><label>ACTIVITIES </label>
                                </div>
                                <div className="col">
                                    <span><CountUp duration={2.75} start={1} end={5988} /></span><label>KILOMETERS</label>
                                </div>
                                <div className="col">
                                    <span><CountUp duration={2.25} start={-45} end={6.59} /></span><label>KMs ~</label>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <p className="d-none">d</p>
                    )}
                    <p className="mt-2 mt-md-4">{Text}</p>
                    <p className={Home ? 'none' : 'pb-1 pb-md-2'}>{Text2}</p>
                    <div className="text-xs-center text-lg-left">
                        {Tags.map((Tag, index) => <span key={index} className="badge bg-light text-dark mb-2">{Tag}</span>)}
                    </div>
                    <div className={Signature ? 'text-end d-block' : 'd-none'}><img src={signatureImg} width="100" height="auto" /></div>
                    <div className={Home ? 'd-block' : 'd-none'}>
						<ChatbotApp dorio={Dorio} />
					</div>
                    <div className="d-block mt-3 text-end">
                        <a className={Link ? 'mt-4 actionBtn me-auto' : 'd-none'} href={Link} target="_blank">{BtnLabel}<span className="arrowBtn"></span></a>
                    </div>
                </div>
            </div>
        </div>
    );
}
