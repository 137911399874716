import { React, useEffect, useState } from "react";

export default function Loader() {
    const [loading, setLoading] = useState(false);
    
    // Define the style for the SVG path as an object
    const pathStyle = {
        fill: "#fff",
        strokeWidth: "0px"
    };

    return (
	    <div className="vh-100 d-flex justify-content-center align-items-center text-center"> 
	        <div className="loader">
	            <svg className="dune"  viewBox="0 0 2500.33 982.7">
	                <path d={`
	                    m621.08,367.98C968.94,28.12,1174.86,20.12,1174.86,20.12c469.81-117.95,651.74,313.88,807.68,485.81s333.87,327.87,517.79,465.82c-226.91-104.96-550.78-158.94-550.78-158.94-201.92-43.98-497.11-151.1-427.83-343.86,45.98-127.95-4-243.9-130.95-290.88-138.86-53.14-327.87-49.98-635.44,209.57,0,0-534.74,498.15-755.33,595.11,287.21-274.89,477.13-470.81,621.08-614.76Z
	                `} style={pathStyle} />
	            </svg>
	            <svg className="dune"  viewBox="0 0 2500.33 982.7">
	                <path d={`
	                    m621.08,367.98C968.94,28.12,1174.86,20.12,1174.86,20.12c469.81-117.95,651.74,313.88,807.68,485.81s333.87,327.87,517.79,465.82c-226.91-104.96-550.78-158.94-550.78-158.94-201.92-43.98-497.11-151.1-427.83-343.86,45.98-127.95-4-243.9-130.95-290.88-138.86-53.14-327.87-49.98-635.44,209.57,0,0-534.74,498.15-755.33,595.11,287.21-274.89,477.13-470.81,621.08-614.76Z
	                `} style={pathStyle} />
	            </svg>
	            <svg className="dune"  viewBox="0 0 2500.33 982.7">
	                <path d={`
	                    m621.08,367.98C968.94,28.12,1174.86,20.12,1174.86,20.12c469.81-117.95,651.74,313.88,807.68,485.81s333.87,327.87,517.79,465.82c-226.91-104.96-550.78-158.94-550.78-158.94-201.92-43.98-497.11-151.1-427.83-343.86,45.98-127.95-4-243.9-130.95-290.88-138.86-53.14-327.87-49.98-635.44,209.57,0,0-534.74,498.15-755.33,595.11,287.21-274.89,477.13-470.81,621.08-614.76Z
	                `} style={pathStyle} />
	            </svg>
	        </div>
        </div>
    )
}
