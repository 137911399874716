import { React, useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";

import './App.scss';

import Home from './components/home';
import Navigation from './components/navigation';
import About from './components/about';
import Contact from './components/contact';
import Loader from './components/loader';
import Skills from './components/skills';
import Projects from './components/projects';

const App = () => {
    const [loading, setLoading] = useState(false);
    const location = useLocation(); // Use useLocation to get the current route

    // Function to set body class based on route
    useEffect(() => {
        const currentPath = location.pathname;
        const bodyClass = currentPath === "/" ? "home-page" : currentPath.replace("/", "") + "-page";
        
        document.body.className = bodyClass; // Set the body class based on the route
        
        // Clean up the class when the component unmounts or route changes
        return () => {
            document.body.className = "";
        };
    }, [location]);

    useEffect(() => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
        }, 3500);
    }, []);

    return (
        <div className="App">
            {loading ? (
                <Loader />
            ) : (
                <div className="main-container">
                    <header>
                        <Navigation />
                        <Routes>
                            <Route exact path="/" element={<Home />} />
                            <Route path="/projects" element={<Projects />} />
                            <Route path="/skills" element={<Skills />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/:customer" element={<Home />} />
                        </Routes>
                    </header>
                </div>
            )}
        </div>
    );
};

const RootApp = () => (
    <Router>
        <App />
    </Router>
);

export default RootApp;
