import React, { useRef } from 'react';
import { Helmet } from "react-helmet";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import emailjs from '@emailjs/browser';
import signatureImg from '../images/sign.png';

export default function Contact() {
    const form = useRef();

     const sendEmail = (e) => {
       e.preventDefault();

       emailjs.sendForm('service_g3foj1g', 'template_mjfm84x', form.current, '8D-D1_9mtR0zvrvf3')
         .then((result) => {
             console.log(result.text);
         }, (error) => {
             console.log(error.text);
         });
     };   
    return (
            <div>
                <Helmet>
                    <title>Get in Touch with Dunes Consulting for Innovative IT Solutions in Tokyo / Shizuoka,  Japan</title>
                    <meta name="description" content="Contact Julien bourdon Dunes Consulting today to learn more about our innovative IT solutions. Whether you need custom software development, IT consulting, or project management services, our team of skilled professionals is dedicated to delivering solutions that drive business growth and success. Fill out our contact form or give us a call to get started on your digital transformation journey." />
                </Helmet>
                    <div className="tv">
                        <div id="terminal">
                        <div className="container h-100 masthead-contact ">
                            <div className="row align-items-center">
                                
                              <div className="col-12 col-lg-6 offset-lg-3 text-center">
                                <h3 className="fw-strong text-white headline-p">Get In Touch</h3>
                                <p className="lead text-white mt-8 pt-3">If you have an offer, opportunity, or introduction that might make my life more interesting feel free to reach out <a className="linkColor" href="mailto:julien@dunes.jp">here</a> or you can directly <a className="linkColor" href="https://calendly.com/virtuous-design/15-min-consultation" target="_blank">book a 15 minutes consultation through calendly</a>.</p>
                                <div className="text-end d-block mb-4"><img src={signatureImg} width="100" height="auto"/></div>
                              </div>
                            </div>
                            <form ref={form} onSubmit={sendEmail}>
                            <div className="row">
                                 
                                    <Form.Group className="mb-3 pt-6 col col-12 col-lg-3 offset-lg-3" controlId="exampleForm.ControlInput1">
                                     
                                      <Form.Control type="text"  className="mb-3" name="from_name" placeholder="Enter Your Name" />
                                    </Form.Group>
                                     <Form.Group className="mb-3 pt-6 col col-12 col-lg-3" controlId="exampleForm.ControlInput2">
        
                                      <Form.Control type="email" name="from_email" placeholder="Enter Your Email" />
        
                                    </Form.Group>
                            </div>
                            <div className="row">
                                     <Form.Group className="mb-3 col-12 col-lg-6 offset-lg-3" controlId="exampleForm.ControlTextarea1">
                                      <Form.Control as="textarea" rows={3}  name="message" placeholder="Enter Your Message"/>
                                    <div className=" text-end gap-2 mt-3 pb-3">
                                      <input className="mt-3 actionBtn ms-auto" type="submit" value="SEND MESSAGE" />
                                    </div>
                                    </Form.Group>
                                    
                            </div>
                            </form>
                          </div>
                        </div>
                        <div className="flicker"></div>
                        <div className="noise"></div>
                    </div>
        </div>
    )

}